import { IStatusReport } from './BasicWorkReportGenerator';
import { IStatusReportWithMessage } from './WithMessageWorkReportGenerator';
import { ChannelMessageElement } from './messages/ChannelMessageElement';

export enum BedrockReportType {
  BASIC = 'BASIC',
  WITH_MESSAGE = 'WITH_MESSAGE',
}

export interface IBaseBedrockReport {
  type: BedrockReportType;
}

export interface IBasicBedrockReport {
  type: BedrockReportType.BASIC;
  output: IStatusReport;
}

export interface IWithMessageBedrockReport {
  type: BedrockReportType.WITH_MESSAGE;
  output: IStatusReportWithMessage;
  referencedMessages: Record<string, ChannelMessageElement>;
}

export type IBedrockReport = IBasicBedrockReport | IWithMessageBedrockReport;
