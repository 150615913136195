import { BedrockReportType } from '@backend/bedrock/IBedrockReport';
import { BedrockMechanism, RunBedrockRequest } from '@shared/bedrock';
import { IUser, UserToken } from '@shared/types';
import { SelectDateHour } from '@web/components/SelectDateHour';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUsers } from '@web/components/users/SelectUsers';
import { useScheduledEvent } from '@web/surveys/cycles/useScheduledEvent';
import { Checkbox, InputNumber, Modal, Select, message } from 'antd';
import * as React from 'react';

export const SetupRunModal: React.FC<{
  open: boolean;
  onCancel: () => void;
  onSubmit: (data: RunBedrockRequest) => void;
  userOptions: IUser[];
}> = ({ open, onCancel, onSubmit, userOptions }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [mechanism, setMechanism] = React.useState<
    BedrockMechanism | undefined
  >(undefined);
  const [adjacentMessageCount, setAdjacentMessageCount] = React.useState(2);
  const [maxEntries, setMaxEntries] = React.useState(10);
  const [suggestedEntryCount, setSuggestedEntryCount] = React.useState(5);
  const [showTargetUsers, setShowTargetUsers] = React.useState(false);
  const [targetUserTokens, setTargetUserTokens] = React.useState<UserToken[]>(
    [],
  );
  const [type, setType] = React.useState<BedrockReportType | undefined>(
    undefined,
  );

  const startDate = useScheduledEvent();
  const endDate = useScheduledEvent();

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleOk = async () => {
    setIsSubmitting(true);
    try {
      const data: RunBedrockRequest = {
        mechanism,
        maxEntryCount: maxEntries,
        suggestedEntryCount,
        startDate: new Date(`${startDate.date} ${startDate.hour}:00`),
        endDate: new Date(`${endDate.date} ${endDate.hour}:00`),
        targetUserTokens: showTargetUsers ? targetUserTokens : undefined,
        adjacentMessageCount,
        type,
      };
      await onSubmit(data);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Run"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSubmitting}
      onCancel={onCancel}
      width="500px"
      okText="Run"
      okButtonProps={{
        disabled:
          isSubmitting ||
          !mechanism ||
          !type ||
          (showTargetUsers && targetUserTokens.length === 0),
      }}
    >
      <Column gap={12}>
        <Column gap={6}>
          <Text>Start Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={startDate.date}
            hour={startDate.hour}
            onChange={startDate.onChange}
            timezone={startDate.timezone}
          />
        </Column>
        <Column gap={6}>
          <Text>End Date</Text>
          <SelectDateHour
            disabled={isSubmitting}
            date={endDate.date}
            hour={endDate.hour}
            timezone={endDate.timezone}
            onChange={endDate.onChange}
          />
        </Column>
        <Column gap={6}>
          <Text>Maximum Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={maxEntries}
            onChange={(count) => {
              setMaxEntries(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Suggested Entries</Text>
          <InputNumber
            disabled={isSubmitting}
            value={suggestedEntryCount}
            onChange={(count) => {
              setSuggestedEntryCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Adjacent Message Count</Text>
          <InputNumber
            disabled={isSubmitting}
            value={adjacentMessageCount}
            onChange={(count) => {
              setAdjacentMessageCount(count);
            }}
          />
        </Column>
        <Column gap={6}>
          <Text>Mechanism</Text>
          <SelectMechanism disabled={isSubmitting} onChange={setMechanism} />
        </Column>
        <Column gap={6}>
          <Text>Report Type</Text>
          <SelectReportType disabled={isSubmitting} onChange={setType} />
        </Column>
        <Column gap={6}>
          <Checkbox
            checked={showTargetUsers}
            onChange={() => {
              setShowTargetUsers(!showTargetUsers);
            }}
          >
            <Text>Target Users</Text>
          </Checkbox>
          {showTargetUsers && (
            <SelectUsers
              disabled={isSubmitting}
              initialUsers={userOptions.filter((u) =>
                targetUserTokens.includes(u.token),
              )}
              includeOnly={userOptions.map((u) => u.token)}
              onChange={(userTokens) => {
                setTargetUserTokens(userTokens);
              }}
            />
          )}
        </Column>
      </Column>
    </Modal>
  );
};
interface MechanismOption {
  label: string;
  value: RunBedrockRequest['mechanism'];
}
const SelectMechanism: React.FC<{
  disabled: boolean;
  onChange: (mechanism: RunBedrockRequest['mechanism']) => void;
}> = ({ disabled, onChange }) => {
  const options: MechanismOption[] = [
    {
      label: 'Journal Entries',
      value: 'journal',
    },
    {
      label: 'Direct Message Users',
      value: 'dm',
    },
  ];

  return <Select disabled={disabled} options={options} onChange={onChange} />;
};

interface ReportOption {
  label: string;
  value: BedrockReportType;
}
const SelectReportType: React.FC<{
  disabled: boolean;
  onChange: (type: BedrockReportType) => void;
}> = ({ disabled, onChange }) => {
  const options: ReportOption[] = [
    {
      label: BedrockReportType.BASIC,
      value: BedrockReportType.BASIC,
    },
    {
      label: BedrockReportType.WITH_MESSAGE,
      value: BedrockReportType.WITH_MESSAGE,
    },
  ];

  return <Select disabled={disabled} options={options} onChange={onChange} />;
};
